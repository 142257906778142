@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Fraunces:ital,opsz,wght,WONK@0,9..144,100..900,1;1,9..144,100..900,1&display=swap');

html {
    height: 100%;
}

body {
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    font-style: normal;

    height: 100%;
}

.base-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Fraunces", serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
    font-variation-settings:
        "SOFT" 0,
        "WONK" 1;
}

h1 {
    color: $blue;
}

h2, h3, h4, h5, h6 {
    font-weight: normal;
}


.breadcrumb {
    background: white;
}

.footer__main {
    background: $blue;
}

.text-light a {
    color: white;
    text-decoration: underline;
}

.dropzone {
    border: 2px dashed #dedede;
    border-radius: 5px;
    background: #f5f5f5;
    cursor: pointer;
}

.dropzone i{
    font-size: 5rem;
}

.dropzone .dz-message {
    color: rgba(0,0,0,.54);
    font-weight: 500;
    font-size: initial;
    text-transform: uppercase;
}

.dropzone .dz-preview.dz-image-preview {
    background: none;
}

.dz-image {
    background: white;
    margin-bottom: 1em;
}

.btn-primary {
    border-color: $btn-background-color;
    background-color: $btn-background-color;
}

blockquote.testimonial {
    background: white;
    padding: 1rem;
    p {
        font-style: italic;
    }

    i.fa-solid, i.fa-regular {
        color: $btn-background-color;
    }
}

.image-choice {
    display: flex;
    flex-wrap: wrap;

    width: 100%;

    .form-check {
        padding: 0;
    }

    label {
        position: relative;
        background: $body-bg;
        padding: 0.5rem;
        transition: border .3s, transform .3s;
        border: 4px solid transparent;
        text-align: center;
        cursor: pointer;
        transform: scale(0.9);

        &:hover {
            border: 4px solid $btn-background-color;
            transform: scale(2);
            z-index: 2;
        }
    }

    img{
        display: block;
        object-fit: cover;
        width: 150px;
        height: 225px;
        margin: 0 auto;
        padding: 0.5rem
    }

    input[type=radio] {
        position: absolute;
        width: 100%;
        height: 100%;

        appearance: none;

        &:checked + label {
            border: 4px solid $btn-background-color;
        }
        &:checked + label:not(:hover) {
            transform: scale(1);
        }
    }
}
