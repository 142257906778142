$blue: #2E3165;
$headings-color: black;
$body-bg: #FBF4EE;
$dark: $blue;
$btn-font-weight: bold;
$btn-border-radius: 4px;
$btn-background-color: #EB8167;
$link-decoration: underline;
$yiq-contrasted-threshold: 200;
$headings-margin-bottom: 1rem;
$paragraph-margin-bottom: 1rem;
